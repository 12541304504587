import React from "react";
import MainWrap from "../components/MainWrap";

const settings = {
  name: "Contact Us",
  img1: "overhead.jpg",
  img2: "maint3.jpg",
  sideImage: "bijanair_hangar_300c.jpg",
  title: "Contact Bijan Air, Inc.",
  heading: "Contact Bijan Air, Inc.",
  subheadIndex: 6,
  subheadArrow: "Bijan Air, Inc.",
  subheadText:
    "Bijan Air, Inc. was established in August of 1988 for the purpose of providing professional helicopter services to the public.",
  msg: "Our Expertise"
};

const ContactPage = ({ location }) => (
  <MainWrap settings={settings} location={location}>
    <p>
      email: <a href="mailto:bijan@bijanair.com">bijan@bijanair.com</a>
    </p>
    <p>
      Phone (734) 769-8400<span className="phoneSeparator">/</span>Fax: (734)
      769-6555
      <br />
      747 Airport Dr.
      <br />
      Ann Arbor, Michigan 48108
      <br />
    </p>
    <p>
      We are located at Ann Arbor Airport, in Ann Arbor Michigan, near State St.
      and Ellsworth. Click to{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Bijan+Air+Inc,+Ann+Arbor,+MI+48108&sll=42.235762,-83.737535&sspn=0.038002,0.063343&ie=UTF8&hq=Bijan+Air+Inc,&hnear=Ann+Arbor,+Washtenaw,+Michigan+48108&t=h&ll=42.227246,-83.744659&spn=0.038007,0.063343&z=14"
      >
        get directions to our hangar.
      </a>
    </p>

    <iframe
      title="Local Map to Bijanair"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2954.4758007796977!2d-83.74468399999998!3d42.22564699999998!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883caff3ceb0616b%3A0x60233f8ad2c6f59a!2sBijan+Air+Inc!5e0!3m2!1sen!2sus!4v1413065779572"
      width="425"
      height="350"
      frameBorder="0"
      style={{ border: 0 }}
    />
  </MainWrap>
);

export default ContactPage;
