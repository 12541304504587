import React from "react";

const SubHead = ({ settings, children }) => (
  <div id="subhead_container">
    <div id={settings.img2 ? "subhead" : "subhead_large"}>
      <div id="subhead_content">
        <h1>
          0{settings.subheadIndex}. {settings.subhead || settings.name}
        </h1>
        {settings.subheadArrow && <h2>{settings.subheadArrow}</h2>}
        <p>{settings.subheadText}</p>
        {children}
      </div>
    </div>
    <img src={"/images/" + settings.img1} alt={settings.title} id="img1" />
    {settings.img2 && (
      <img src={"/images/" + settings.img2} alt={settings.subhead} id="img2" />
    )}
  </div>
);

export default SubHead;
