import React from "react";
import SubHead from "../components/SubHead/";
import Layout from "./Layout";

const MainWrap = ({ settings, Subheader, children, location }) => {
  Subheader = Subheader || SubHead;
  return (
    <Layout location={location}>
      <div>
        <Subheader settings={settings} />

        {settings.sideImage && (
          <img
            alt="Helicopter flying"
            src={"/images/" + settings.sideImage}
            id="side_image"
          />
        )}

        <div id="content">
          <h1>{settings.heading}</h1>
          {children}
        </div>
      </div>
    </Layout>
  );
};

export default MainWrap;
